@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400;500;600;700&family=Jost:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200..800&display=swap');


#info-view {
  text-align: center;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 1400px; 
  margin: 0 auto;
  width: 90%;
}

.grid-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2rem auto 2rem auto;
}

.hacker-countdown-title {
  text-align: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  background: linear-gradient(0deg, #8D52DA, #4B5DE6, #169AE5);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 600 !important;
  margin: 2rem 0;
}

/* wifi component */
.wifi-section {
  margin-top: 1rem; 
  padding: 1.5rem 0;
  width: 100%;
  max-width: 1400px; 
}

@media (min-width: 1600px) {
    .wifi-section {
        max-width: 1600px;
    }
}