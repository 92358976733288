.workshop-day {
  margin-bottom: 40px;
  font-family: 'Jost', sans-serif;
}

.workshop-day:first-of-type {
  margin-top: 60px;
}

.workshop-day h5 {
  text-align: center;
  background: linear-gradient(rgba(20, 90, 171, 1), rgba(145, 152, 205, 1));
  font-weight: 600;
  color: white;
  padding: 13px 0;
  margin: 0;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.workshop-day .workshops {
  margin-top: 10px;
  /*   */
  border-left: 1px solid rgba(188, 209, 255, 1);
  border-right: 1px solid rgba(188, 209, 255, 1);
  border-bottom: 1px solid rgba(188, 209, 255, 1);
}

.workshop-day .event-item-container {
  user-select: none;
}
