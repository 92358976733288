@import url('https://fonts.googleapis.com/css2?family=Sulphur+Point:wght@300;400;700&display=swap');

p {
    font-family: 'Sulphur Point', sans-serif;
    color: #476AB8;
}

.toggle-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.map-layout {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}

#view-switch {
    position: relative;
    width: 80px;
    height: 40px;
    background-color: #9F9FF5;
    border-radius: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

#view-switch.active {
    background-color: #01A2D9;
}

#view-switch .toggle-slider {
    position: absolute;
    top: 4px;
    left: 5px;
    width: 30px;
    height: 30px;
    background: #FFFFFF;
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
    z-index: 2;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

#view-switch.active .toggle-slider {
    transform: translateX(38px);
}

#view-switch .toggle-label {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 7px;
    font-size: 1.2rem;
    font-weight: 300;
    color: #FFFFFF;
    z-index: 1;
    user-select: none;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
}

#view-switch:not(.active) .toggle-label span:nth-child(1) {
    opacity: 0;
}

/* Hide "2D" text in active state */
#view-switch.active .toggle-label span:nth-child(2) {
    opacity: 0;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .map-layout {
        flex-direction: column;
        /* Stack vertically on smaller screens (tablets, etc.) */
    }

    .image-column {
        margin-bottom: 20px;
    }

    .floor-layouts img {
        width: 90%;
        /* Scale image */
    }

    #view-switch {
        width: 80px;
        height: 40px;
    }

    #view-switch .toggle-slider {
        width: 30px;
        height: 30px;
    }

    #view-switch.active .toggle-slider {
        transform: translateX(38px); /* Adjust if needed */
    }

    #view-switch .toggle-label {
        font-size: 0.9rem;
    }

    /* Add horizontal margins for the heading row and paragraph on screens up to 1024px */
    .justify-content-between.align-items-center {
        margin-left: 20px;
        margin-right: 20px;
    }

    /* The + p selector targets the paragraph immediately after the heading row */
    .justify-content-between.align-items-center + p {
        margin-left: 20px;
        margin-right: 20px;
    }
}

/* Further adjustments for very small screens (below 768px) */
@media (max-width: 768px) {
    .map-layout {
        /* Remove left/right margins so it's centered on mobile */
        margin-left: 0;
        margin-right: 0;

        /* Ensure the content is centered */
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    /* Optionally adjust the heading & paragraph margins on very small screens */
    .justify-content-between.align-items-center {
        margin-left: 15px;  /* or 20px, if you prefer */
        margin-right: 15px;
    }

    .justify-content-between.align-items-center + p {
        margin-left: 15px;
        margin-right: 15px;
    }
}

.skeleton-loader {
    width: 100%;
    height: 200px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    border-radius: 10px;
}

@keyframes loading {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}

.skeleton-loader {
    width: 100%;
    height: 200px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite linear;
    border-radius: 10px;
}

@keyframes loading {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}