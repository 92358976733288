@import url(https://rsms.me/inter/inter.css);
@import url(https://fonts.googleapis.com/css2?family=Urbanist:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Urbanist:wght@400;500;600;700&family=Jost:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200..800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sulphur+Point:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@500;700&family=Jost:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sulphur+Point:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sulphur+Point:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jost:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jost:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jost:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600;700&display=swap);
@font-face {
	font-family: 'Harabara';
	src: url(../../static/media/HarabaraMais.9840b64b.otf);
}

@supports (font-variation-settings: normal) {
	html {
		font-family: 'Inter var', sans-serif;
	}
}

body {
	margin: 0;
	font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
		'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: Inter;
}

body {
	margin-top: 100px;
	/* Adjust this based on your navbar height */
}

#switch {
	max-width: 1280px;
	margin: auto;
	padding: 32px 8px;
}
#schedule {
    -webkit-user-select: none;
            user-select: none;
}

/* button group container */
.schedule-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

/* day selector container */
.day-selector {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    margin: 0 auto;
    grid-gap: 50px;
    gap: 50px;
}

/* day button styles */
.day-button {
    flex-grow: 1;
    background-color: #f7f7f7;
    color: #898989;
    font-family: 'Urbanist', sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    border: none;
    border-radius: 12px;
    white-space: normal;
    padding: 10px 20px;
    min-width: 200px;
    max-width: 300px;
    transition: all 0.3s ease-in-out;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.06);
    outline: none;
    margin-bottom: 0.5rem;
}

.day-button:focus,
.day-button:active,
.day-button:hover {
    outline: none;
    box-shadow: none;
    border: none;
}

/* active button gradient text effect */
.day-button.active {
    background-image: linear-gradient(
        135deg, 
        #bb4fcc, 
        #8d52da, 
        #4b5de6, 
        #169ae5
    );
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -ms-background-clip: text;
    -o-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}

/* responsive adjustments */
@media (max-width: 768px) {
    .day-selector {
        grid-gap: 1rem;
        gap: 1rem;
        padding: 1rem;
        margin-bottom: 1rem;
    }

    .day-button {
        padding: 1rem 1.5rem;
        font-size: 1rem;
        max-width: 120px;
    }
}

/* fade-in animation */
@keyframes fadeInAnimation {
    0% {
        transform: translateY(-40%);
        opacity: 0;
    }
    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}
.day-selector {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    grid-gap: 50px;
    gap: 50px;
}

/* Responsive gap adjustments */
@media (max-width: 1024px) {
    .day-selector {
        grid-gap: 35px;
        gap: 35px;
    }
}

@media (max-width: 768px) {
    .day-selector {
        grid-gap: 20px;
        gap: 20px;
    }
}

@media (max-width: 480px) {
    .day-selector {
        grid-gap: 10px;
        gap: 10px;
    }
}

.day-button {
    flex-grow: 1;
    background-color: #F7F7F7;
    color: #898989;
    font-family: 'Urbanist', sans-serif;
    font-weight: 500; 
    text-transform: uppercase;
    border: none;
    border-radius: 12px; 
    white-space: normal;
    padding: 10px 20px; 
    min-width: 200px; 
    transition: all 0.3s ease-in-out;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.06);
    outline: none;
}

.day-button:focus,
.day-button:active,
.day-button:hover {
    outline: none;
    box-shadow: none;
    border: none;
}

.day-button.active {
    background-image: linear-gradient(
        135deg, 
        #BB4FCC, 
        #8D52DA, 
        #4B5DE6, 
        #169AE5
    );
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text; 
    -ms-background-clip: text;
    -o-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
    .day-selector {
        flex-wrap: nowrap;
        overflow-x: auto;
        justify-content: flex-start;
        padding: 0.5rem 0; 
        margin-bottom: 0.7rem;
    }

    .day-button {
        min-width: auto;
        width: 80px; 
        font-size: 0.8rem; 
        padding: 0.5rem 0.8rem; 
        border-radius: 8px; 
    }
}
#timeline {
    position: relative;
    overflow: auto;
    width: 100%;
    outline: none;
    height: 80vh;
    border-radius: 12px; 
    background: rgb(255, 255, 255);
    border: 1px solid rgb(216, 228, 255);
    padding: 10px;
}

.timeline-label,
.timeline-label-marker {
    position: absolute;
    font-family: system-ui, -apple-system, sans-serif;
}

.timeline-label {
    top: 6px;
    font-size: 12px;
    color: #555;
    font-weight: normal;
    transition: color 0.2s ease;
    text-align: left;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.timeline-label-marker {
    height: 2%;
    width: 2px;
    background: rgba(0, 0, 0, 0.2);
}

#timeline-slider {
    position: absolute;
    height: 100%;
    width: 3px;
    background: rgba(255, 0, 0, 0.4);
    z-index: 2;
    border-radius: 1px;
}

#timeline-slider .triangle-pointer {
    position: absolute;
    left: -5px;
}

#timeline-slider .triangle-pointer.top {
    top: 0;
}

#timeline-slider .triangle-pointer.bottom {
    bottom: 0;
}

.timeline-track-item.gantt-style {
    position: relative;
    transition: all 0.2s ease;
    cursor: pointer;
    height: 40px;
    border-radius: 4px; 
    margin-bottom: 3px;
}

.timeline-track-item.gantt-style p {
    line-height: 1.2;
    position: absolute;
    margin-left: 7px;
    margin-right: 7px;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    color: white;
    display: flex;
    align-items: center;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    /* Better text truncation */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 20px);
    width: auto;
}

.timeline-track-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #FF7F7F;
    border-radius: 6px;
    z-index: 0;
}

.timeline-track-item:hover {
    opacity: 0.9;
    transform: scale(1.02);
}

.timeline-track-item.past {
    opacity: 0.4;
}

.timeline-track-item.present > .timeline-track-bar {
    box-shadow: 0 0 16px rgba(46, 42, 42, 0.7);
}

#event-list {
    outline: 2px solid rgba(71, 106, 184, 1);
    width: 100%;
    border-radius: 1rem;
    background: rgba(126, 152, 208, 0.18);
    padding: 10px;
    max-height: 550px;
    overflow-y: auto; 
}

.event-item-container {
    background-color: white;
    padding: 1px 1px;
    margin: 8px 0;
    border-radius: 15px;
    transition: background-color 0.3s ease;
    max-width: 100%;
    -webkit-user-select: none;
            user-select: none;
}

.event-item-container:hover {
    background-color: rgba(234, 231, 231, 0.5);
}

.filter-container {
    background-color: white;
    padding: 10px;
    border-radius: 1rem;
    margin-bottom: 20px;
    outline: 2px solid rgba(71, 106, 184, 1);
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns */
    grid-gap: 10px;
    gap: 10px;
}

.filter-container label {
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
}

.filter-container input[type="checkbox"] {
    margin-right: 8px;
}

/* New filter label container */
.filter-label {
    background-color: rgba(71, 106, 184, 1);
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
    font-weight: bold;
    margin-bottom: 3px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

/* uOttaPoints label */
.uoPoints-label {
    background-color: white;
    padding: 10px;
    border-radius: 0.7rem;
    margin-bottom: 20px;
    outline: 2px solid rgba(71, 106, 184, 1);
    font-weight: bold; 
    color: rgba(71, 106, 184, 1);
    text-align: center;
    width: 60%;
    margin-right: auto;
    margin-left: auto;
}
.event-item {
	cursor: pointer;
	margin-top: -2px;
	border-top: 1px solid #ffffff;
}

.event-item:hover {
	background: rgba(0, 0, 0, 0.02);
}

.event-item-content {
	position: relative;
	padding: 10px 52px;
	transition: transform 0.2s;
}

.event-item-content:hover {
	transform: translateX(4px);
}

.event-item-content > .category-circle {
	position: absolute;
	top: 40%;
	left: 24px;
}

@keyframes pulse {
	0% {
		box-shadow: 0px 0px 3px 0px rgba(173, 0, 0, 0.3);
	}
	65% {
		box-shadow: 0px 0px 3px 6px rgba(173, 0, 0, 0.3);
	}
	90% {
		box-shadow: 0px 0px 3px 6px transparent;
	}
}

.event-item-content > .active-circle {
	position: absolute;
	top: 40%;
	right: 32px;
	border-radius: 50%;
	animation-name: pulse;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

.event-item-content > p {
	margin: 0;
}

.event-item.past {
	opacity: 0.1;
	border-top: 1px solid #888;
}

#info-view {
  text-align: center;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 1400px; 
  margin: 0 auto;
  width: 90%;
}

.grid-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2rem auto 2rem auto;
}

.hacker-countdown-title {
  text-align: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  background: linear-gradient(0deg, #8D52DA, #4B5DE6, #169AE5);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 600 !important;
  margin: 2rem 0;
}

/* wifi component */
.wifi-section {
  margin-top: 1rem; 
  padding: 1.5rem 0;
  width: 100%;
  max-width: 1400px; 
}

@media (min-width: 1600px) {
    .wifi-section {
        max-width: 1600px;
    }
}
.button-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
    gap: 1.5rem;
    width: 100%;
    max-width: 1800px;
    margin: 2rem auto;
    padding: 0 5px;
    justify-items: center; 
    align-items: center;
  }
  
  @media (min-width: 1024px) and (max-width: 1440px) {
    .button-grid {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      grid-gap: 1.5rem;
      gap: 1.5rem;
    }
  }
  
  @media (min-width: 1441px) {
    .button-grid {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 2rem;
      gap: 2rem;
    }
  }
  
  @media (max-width: 1023px) {
    .button-grid {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
      gap: 1rem;
      max-width: 600px; 
      margin-left: auto;
      margin-right: auto;
    }
  }

.essential-link-button {
  width: clamp(110px, 12vw, 180px); 
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
}

/* prevent underline on hover */
.essential-link-button:hover {
  text-decoration: none;
  transform: translateY(-3px);
}

/* Default Background Colors */
.essential-link-button.discord {
  background: #8B6AD7;
}

.essential-link-button.dorahacks {
  background: #F99F51;
}

.essential-link-button.guide {
  background: #3BB1D9;
}

.essential-link-button.mentor {
  background: #EFAFE2;
}

/* Hover Effect: Fill from Bottom to Top */
.essential-link-button::before {
  content: "";
  position: absolute;
  bottom: -100%;
  aspect-ratio: 1;
  left: 0;
  width: 100%;
  height: 100%;
  transition: bottom 0.3s ease-in-out;
  z-index: 0;
}

/* Hover Fill Colors */
.essential-link-button.discord::before {
  background: #B59DED;
}

.essential-link-button.dorahacks::before {
  background: #FFC592;
}

.essential-link-button.guide::before {
  background: #9EFAFF;
}

.essential-link-button.mentor::before {
  background: #FFDAF7;
}

.essential-link-button:hover::before {
  bottom: 0; 
}

/* Keep icons & text above the animation */
.essential-link-button-icon,
.essential-link-button-text {
  position: relative;
  z-index: 1;
}

/* Icon Styling */
.essential-link-button-icon {
  width: clamp(2.8rem, 3vw, 3.5rem);
  height: clamp(2.8rem, 3vw, 3.5rem);
  margin-bottom: 0.5rem;
  color: #1F256C;
}

.essential-link-button-icon svg {
  width: 100%;
  height: 100%;
}

/* Text Styling */
.essential-link-button-text {
  font-family: 'Sulphur Point', sans-serif;
  font-size: clamp(1.1rem, 1.2vw, 1.3rem); /* Responsive text size */
  font-weight: 500;
  color: #1F256C;
}

/* button sizing */ 
@media (max-width: 1024px) {
  .essential-link-button {
    width: clamp(140px, 16vw, 200px);
  }
}

@media (max-width: 768px) {
  .essential-link-button {
    width: 100%;
    max-width: 180px; /* More compact for mobile */
    margin: 0 auto;
  }
}
/* Quicksand with Jost as fallback */
.wifi-text {
  flex-grow: 1;
  max-width: 1000px; 
  font-family: 'Quicksand', sans-serif;
  color: #476AB8;
}

.network-name, .network-description {
  font-family: 'Quicksand', 'Jost', sans-serif;
}

.wifi-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    max-width: 1400px; 
    margin: 0 auto;
}

/* ensure WiFi section expands on large screens */
@media (min-width: 1400px) {
    .wifi-container {
        max-width: 1600px;
    }
}

  
.wifi-info {
  display: flex;
  align-items: flex-start;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
  max-width: 1200px;
  padding: 0 2rem; 
}

/* icon sizing */
.wifi-icon-wrapper {
    flex-shrink: 0;
    color: #476AB8;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.wifi-icon {
    width: 2rem;
    height: 2rem;
}

@media (min-width: 768px) {
    .wifi-icon {
      width: 2.5rem;
      height: 2.5rem;
    }
}
  
@media (min-width: 1024px) {
    .wifi-icon {
      width: 3rem;
      height: 3rem;
    }
}

/* text spacing */ 
.network-info {
    margin-bottom: 0.5rem;
}

.network-name {
    font-weight: 700;
}

.network-description {
    font-weight: 500;
}

/* mobile */
@media (max-width: 768px) {
  .wifi-info {
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 90%;
    padding: 0 1rem;
  }

  .wifi-icon-wrapper {
    margin-bottom: 1rem;
  }

  .network-info {
    display: flex;
    flex-direction: column;
    grid-gap: 0.25rem;
    gap: 0.25rem;
  }
}
.workshop-day {
  margin-bottom: 40px;
  font-family: 'Jost', sans-serif;
}

.workshop-day:first-of-type {
  margin-top: 60px;
}

.workshop-day h5 {
  text-align: center;
  background: linear-gradient(rgba(20, 90, 171, 1), rgba(145, 152, 205, 1));
  font-weight: 600;
  color: white;
  padding: 13px 0;
  margin: 0;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.workshop-day .workshops {
  margin-top: 10px;
  /*   */
  border-left: 1px solid rgba(188, 209, 255, 1);
  border-right: 1px solid rgba(188, 209, 255, 1);
  border-bottom: 1px solid rgba(188, 209, 255, 1);
}

.workshop-day .event-item-container {
  -webkit-user-select: none;
          user-select: none;
}

p {
    font-family: 'Sulphur Point', sans-serif;
    color: #476AB8;
}

.toggle-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.map-layout {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}

#view-switch {
    position: relative;
    width: 80px;
    height: 40px;
    background-color: #9F9FF5;
    border-radius: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

#view-switch.active {
    background-color: #01A2D9;
}

#view-switch .toggle-slider {
    position: absolute;
    top: 4px;
    left: 5px;
    width: 30px;
    height: 30px;
    background: #FFFFFF;
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
    z-index: 2;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

#view-switch.active .toggle-slider {
    transform: translateX(38px);
}

#view-switch .toggle-label {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 7px;
    font-size: 1.2rem;
    font-weight: 300;
    color: #FFFFFF;
    z-index: 1;
    -webkit-user-select: none;
            user-select: none;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
}

#view-switch:not(.active) .toggle-label span:nth-child(1) {
    opacity: 0;
}

/* Hide "2D" text in active state */
#view-switch.active .toggle-label span:nth-child(2) {
    opacity: 0;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .map-layout {
        flex-direction: column;
        /* Stack vertically on smaller screens (tablets, etc.) */
    }

    .image-column {
        margin-bottom: 20px;
    }

    .floor-layouts img {
        width: 90%;
        /* Scale image */
    }

    #view-switch {
        width: 80px;
        height: 40px;
    }

    #view-switch .toggle-slider {
        width: 30px;
        height: 30px;
    }

    #view-switch.active .toggle-slider {
        transform: translateX(38px); /* Adjust if needed */
    }

    #view-switch .toggle-label {
        font-size: 0.9rem;
    }

    /* Add horizontal margins for the heading row and paragraph on screens up to 1024px */
    .justify-content-between.align-items-center {
        margin-left: 20px;
        margin-right: 20px;
    }

    /* The + p selector targets the paragraph immediately after the heading row */
    .justify-content-between.align-items-center + p {
        margin-left: 20px;
        margin-right: 20px;
    }
}

/* Further adjustments for very small screens (below 768px) */
@media (max-width: 768px) {
    .map-layout {
        /* Remove left/right margins so it's centered on mobile */
        margin-left: 0;
        margin-right: 0;

        /* Ensure the content is centered */
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    /* Optionally adjust the heading & paragraph margins on very small screens */
    .justify-content-between.align-items-center {
        margin-left: 15px;  /* or 20px, if you prefer */
        margin-right: 15px;
    }

    .justify-content-between.align-items-center + p {
        margin-left: 15px;
        margin-right: 15px;
    }
}

.skeleton-loader {
    width: 100%;
    height: 200px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    border-radius: 10px;
}

@keyframes loading {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}

.skeleton-loader {
    width: 100%;
    height: 200px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite linear;
    border-radius: 10px;
}

@keyframes loading {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}
.floor-button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 20px;
}

.floor-button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: none !important;
  width: 45px !important;
  height: 45px !important;
  font-size: 1.2rem !important;
  border-radius: 50% !important;
  margin-bottom: 0.5rem !important;
  font-family: 'Sulphur Point', sans-serif !important;
  color: white !important;
  font-weight: 600 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2) !important;
  transition: all 0.3s ease-in-out !important;
  aspect-ratio: 1 !important; 
}

/* Floor specific colors */
.floor-button:nth-child(1) {
  background-color: #52C298 !important;
}

.floor-button:nth-child(2) {
  background-color: #3BB1D9 !important;
}

.floor-button:nth-child(3) {
  background-color: #81E7D4 !important;
}

.floor-button:nth-child(4) {
  background-color: #9F9FF5 !important;
}

.floor-button:nth-child(5) {
  background-color: #F99F51 !important;
}

.floor-button:nth-child(6) {
  background-color: #EFAFE2 !important;
}

/* Hover states */
.floor-button:hover {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3) !important;
  transform: translateY(-2px) !important;
}

/* Selected states with inverted colors */
.floor-button.selected:nth-child(1) {
  background-color: white !important;
  color: #52C298 !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3) !important;
}

.floor-button.selected:nth-child(2) {
  background-color: white !important;
  color: #3BB1D9 !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3) !important;
}

.floor-button.selected:nth-child(3) {
  background-color: white !important;
  color: #81E7D4 !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3) !important;
}

.floor-button.selected:nth-child(4) {
  background-color: white !important;
  color: #9F9FF5 !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3) !important;
}

.floor-button.selected:nth-child(5) {
  background-color: white !important;
  color: #F99F51 !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3) !important;
}

.floor-button.selected:nth-child(6) {
  background-color: white !important;
  color: #EFAFE2 !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3) !important;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .floor-button-group {
    flex-direction: row !important;
    justify-content: center !important;
    margin-bottom: 20px !important;
  }

  .floor-button {
    font-size: 1rem !important;
    width: 50px !important;
    margin: 5px !important;
  }
}

/* Further adjustments for very small screens */
@media (max-width: 768px) {
  .floor-button {
    font-size: 0.8rem !important;
    width: 40px !important;
  }

  .floor-button-group {
    padding: 0 10px !important;
  }
}
/* layout & structure */
#foodmenu-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
}

/* day selector styling */
.day-selector-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.foodmenu-content {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    width: 100%;
    max-width: 1100px;
    margin: 0 auto; 
    padding: 20px;
}

/* meal day section */
.foodmenu-day {
    width: 100%;
    max-width: 900px;
    margin-bottom: 30px;
    font-family: 'Jost', sans-serif;
    text-align: center;
}

/* meal headers */
.foodmenu-day h5 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 700;
    color: white;
    padding: 15px;
    margin: 0 auto;
    border-radius: 12px;
}

/* meal header colors */
.foodmenu-day h5.breakfast {
    background: linear-gradient(135deg, #4b5de6, #3a4fcf);
}

.foodmenu-day h5.lunch {
    background: linear-gradient(135deg, #169ae5, #0d7dbe);
}

.foodmenu-day h5.dinner {
    background: linear-gradient(135deg, #8d52da, #732fb3);
}

/* food card container inside meal day */
.foodmenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
}

/* responsive design */
@media (max-width: 768px) {
    #foodmenu-view {
        padding: 10px;
    }

    .foodmenu-day {
        max-width: 100%;
    }

    .foodmenu {
        padding: 15px;
    }

    .day-selector-container {
        flex-direction: column;
        padding: 10px;
    }
}
.food-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #476AB8;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;
    max-width: 90%;
    background: white;
}

/* Meal Type Header & Time */
.food-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Jost', sans-serif;
    font-weight: 600;
    font-size: 1.6rem;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 12px;
}

.food-card-time {
    font-size: 1.7rem;
    font-weight: 500;
}

/* Adjusting Colors */
.food-card-header.breakfast,
.food-card-time.breakfast,
.food-card-title.breakfast { color: #4B5DE6; }

.food-card-header.lunch,
.food-card-time.lunch,
.food-card-title.lunch { color: #169AE5; }

.food-card-header.dinner,
.food-card-time.dinner,
.food-card-title.dinner { color: #8D52DA; }

/* Restaurant Name */
.food-card-title {
    font-family: 'Jost', sans-serif;
    font-size: 1.8rem;
    font-weight: 400;
    padding-left: 15px;
}

/* Content Container */
.food-card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    padding: 0 15px; 
}

/* Responsive Gap Between Food Description & Image */
.food-card-description {
    font-size: 1.05rem;
    font-family: 'Jost', sans-serif;
    color: #1F256C;
    line-height: 1.5;
    flex: 1 1;
}

/* GAP ADDED HERE - Only on Large Screens */
.food-card-content {
    grid-gap: 60px;
    gap: 60px; 
}

/* Item Styling */
.food-card-item {
    margin-bottom: 20px; 
}

.item-category {
    font-weight: 500;
}

.item-description {
    font-weight: 400; /* Regular weight */
    color: #1F256C; /* Ensures readability */
}

.food-card-image {
    width: 280px;
    height: 180px;
    border-radius: 8px;
    overflow: hidden;
    background: #e0e0e0;
}

/* Skeleton Loader for Empty Image */
.skeleton-loader {
    width: 100%;
    height: 100%;
    background: #e0e0e0;
    border-radius: 8px;
}

.food-card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Skeleton Loader for Empty Image */
.skeleton-loader {
    width: 100%;
    height: 100%;
    background: #e0e0e0;
    border-radius: 8px;
}

/* Mobile Responsive */
@media (max-width: 768px) {
    .food-card {
        padding: 15px;
    }

    .food-card-content {
        flex-direction: column;
        padding: 10px;
        grid-gap: 0;
        gap: 0; 
    }

    .food-card-image {
        width: 100%;
        height: 220px;
        margin-top: 15px;
    }

    .food-card-item {
        margin-bottom: 8px;
    }
}
#contact-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 18px 20px 20px;
    margin: 0 auto;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
}

.contact-title {
    font-family: 'Plus Jakarta Sans', sans-serif;
    background: linear-gradient(90deg, #8D52DA, #4B5DE6, #169AE5);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 4rem;
}

.contact-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    width: 100%;
    padding: 0 20px;
}

.contact-text {
    text-align: left;
    font-family: 'Jost', sans-serif;
    color: #4B75DB;
    max-width: 50%;
}

.contact-section {
    margin-bottom: 2rem;
}

.contact-section h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.contact-section p {
    font-size: 1.125rem;
    font-weight: 400;
}

.contact-image {
    max-width: 50%;
    display: flex;
    justify-content: center;
}

.contact-image img {
    width: 100%;
    max-width: 500px;
    height: auto;
    object-fit: contain;
}

@media (max-width: 768px) {
    .contact-content {
        flex-direction: column;
        text-align: center;
    }

    .contact-text {
        max-width: 100%;
        margin-bottom: 2rem;
    }

    .contact-section {
        text-align: center;
    }

    .contact-image {
        max-width: 80%;
    }
}
body {
  font-family: 'Jost', sans-serif;
}

#challenge-view {
  margin-top: 70px;
}

.icon {
  width: 170px;
  height: 40px;
  margin-top: -40px;
  margin-left: 10px;
  background: linear-gradient(rgba(20, 90, 171, 1), rgba(145, 152, 205, 1));
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  color: white;
  padding: 10px;
}

.text1 {
  font-size: 16px;
  font-weight: 600;
}

.text2 {
  font-weight: normal;
  color: #000000;
}

.card {
  position: relative;
  padding: 1.5rem;
  border-radius: 10px;
  max-width: 95%;
  margin: 0 auto;
}

.card .text-content {
  padding-right: 190px;
}

.card .company-logo-box {
  position: absolute;
  top: 50%;
  right: 45px;
  transform: translateY(-50%);
  background-color: #f1f1f1;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card .company-logo-box img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.truncated-text .content.truncated {
  position: relative;
}

.see-more-btn:hover {
  text-decoration: underline;
}

.fade-overlay {
  pointer-events: none;
}
/* Customize the entire scrollbar */
::-webkit-scrollbar {
    width: 15px; /* Adjust width of the scrollbar */
    height: 15px; /* If horizontal scrollbar is used */
}

/* Customize the thumb (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
    background-color: #E3E3E3; /* Color of the thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
    border: 2px solid transparent; /* Optional: adds some space around the thumb */
    background-clip: content-box; /* Optional: makes the thumb look nicer */
}

/* Customize the track (the area the thumb slides in) */
::-webkit-scrollbar-track {
    background: transparent; /* Light gray color for the track */
    border-radius: 10px; /* Rounded corners for the track */
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* .fade-in-message {
    animation: fadeIn 0.3s ease-out forwards;
} */


.fade-in-char {
    opacity: 0;
    animation: fadeInChar 0.3s ease forwards;
}

@keyframes fadeInChar {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
/* Logo styles */
.logo {
  width: 60px;
  height: auto;
  display: block;
}

/* Logo link */
.logo-link {
  left: 20px;
  z-index: 1;
}

/* Main navbar styles */
#navbar-main {
  z-index: 1000;
  -webkit-user-select: none;
          user-select: none;
  background: rgba(255, 255, 255, 0.80);
  width: 90%;
  margin: 40px auto;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0;
  box-sizing: border-box;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  height: 70px;
}

/* Navigation link styles */
#basic-navbar-nav .nav-link {
  color: #476AB8;
  padding: 10px 20px;
  margin: 5px;
  font-weight: regular;
  align-items: center;
  border-radius: 2rem;
  transition: background-color 0.3s ease, padding 0.3s ease;
}

#basic-navbar-nav .nav-link:hover {
  background-color: rgb(224, 236, 255);
  text-decoration: none;
  border-radius: 2rem;
}

/* Hamburger menu styles */
.navbar-toggler {
  border: none;
  padding: 0.5rem;
  background: transparent;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.hamburger-icon {
  color: rgba(71, 106, 184, 1);
}

/* Ensure collapse animation works */
.navbar-collapse {
  transition: height 0.3s ease, opacity 0.3s ease;
}

/* Responsive styles */
@media (max-width: 1400px) {
  #navbar-main {
    width: 90%;
  }

  #navbar-main.expanded {
    height: auto;
    border-radius: 25px;
    padding-bottom: 15px;
  }

  #basic-navbar-nav .nav-link {
    text-align: center;
    margin: 5px 0;
  }

  .logo-link {
    position: relative !important;
    left: 0;
  }
}

@media (min-width: 1401px) {
  .navbar-collapse {
    display: flex;
    justify-content: center;
  }

  #basic-navbar-nav .nav-link {
    padding: 10px 20px;
  }

  .logo {
    width: 50px; 
    height: auto;
    padding-bottom: 10px; 
  }
  
}

/* Even smaller logo for mobile screens */
@media (max-width: 768px) {
  .logo {
    width: 50px; 
  }
}
