@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@500&display=swap');

.day-selector {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    gap: 50px;
}

/* Responsive gap adjustments */
@media (max-width: 1024px) {
    .day-selector {
        gap: 35px;
    }
}

@media (max-width: 768px) {
    .day-selector {
        gap: 20px;
    }
}

@media (max-width: 480px) {
    .day-selector {
        gap: 10px;
    }
}

.day-button {
    flex-grow: 1;
    background-color: #F7F7F7;
    color: #898989;
    font-family: 'Urbanist', sans-serif;
    font-weight: 500; 
    text-transform: uppercase;
    border: none;
    border-radius: 12px; 
    white-space: normal;
    padding: 10px 20px; 
    min-width: 200px; 
    transition: all 0.3s ease-in-out;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.06);
    outline: none;
}

.day-button:focus,
.day-button:active,
.day-button:hover {
    outline: none;
    box-shadow: none;
    border: none;
}

.day-button.active {
    background-image: linear-gradient(
        135deg, 
        #BB4FCC, 
        #8D52DA, 
        #4B5DE6, 
        #169AE5
    );
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text; 
    -ms-background-clip: text;
    -o-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
    .day-selector {
        flex-wrap: nowrap;
        overflow-x: auto;
        justify-content: flex-start;
        padding: 0.5rem 0; 
        margin-bottom: 0.7rem;
    }

    .day-button {
        min-width: auto;
        width: 80px; 
        font-size: 0.8rem; 
        padding: 0.5rem 0.8rem; 
        border-radius: 8px; 
    }
}