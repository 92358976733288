@import url('https://fonts.googleapis.com/css2?family=Sulphur+Point:wght@400;700&display=swap');

.essential-link-button {
  width: clamp(110px, 12vw, 180px); 
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
}

/* prevent underline on hover */
.essential-link-button:hover {
  text-decoration: none;
  transform: translateY(-3px);
}

/* Default Background Colors */
.essential-link-button.discord {
  background: #8B6AD7;
}

.essential-link-button.dorahacks {
  background: #F99F51;
}

.essential-link-button.guide {
  background: #3BB1D9;
}

.essential-link-button.mentor {
  background: #EFAFE2;
}

/* Hover Effect: Fill from Bottom to Top */
.essential-link-button::before {
  content: "";
  position: absolute;
  bottom: -100%;
  aspect-ratio: 1;
  left: 0;
  width: 100%;
  height: 100%;
  transition: bottom 0.3s ease-in-out;
  z-index: 0;
}

/* Hover Fill Colors */
.essential-link-button.discord::before {
  background: #B59DED;
}

.essential-link-button.dorahacks::before {
  background: #FFC592;
}

.essential-link-button.guide::before {
  background: #9EFAFF;
}

.essential-link-button.mentor::before {
  background: #FFDAF7;
}

.essential-link-button:hover::before {
  bottom: 0; 
}

/* Keep icons & text above the animation */
.essential-link-button-icon,
.essential-link-button-text {
  position: relative;
  z-index: 1;
}

/* Icon Styling */
.essential-link-button-icon {
  width: clamp(2.8rem, 3vw, 3.5rem);
  height: clamp(2.8rem, 3vw, 3.5rem);
  margin-bottom: 0.5rem;
  color: #1F256C;
}

.essential-link-button-icon svg {
  width: 100%;
  height: 100%;
}

/* Text Styling */
.essential-link-button-text {
  font-family: 'Sulphur Point', sans-serif;
  font-size: clamp(1.1rem, 1.2vw, 1.3rem); /* Responsive text size */
  font-weight: 500;
  color: #1F256C;
}

/* button sizing */ 
@media (max-width: 1024px) {
  .essential-link-button {
    width: clamp(140px, 16vw, 200px);
  }
}

@media (max-width: 768px) {
  .essential-link-button {
    width: 100%;
    max-width: 180px; /* More compact for mobile */
    margin: 0 auto;
  }
}