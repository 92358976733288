.event-item {
	cursor: pointer;
	margin-top: -2px;
	border-top: 1px solid #ffffff;
}

.event-item:hover {
	background: rgba(0, 0, 0, 0.02);
}

.event-item-content {
	position: relative;
	padding: 10px 52px;
	transition: transform 0.2s;
}

.event-item-content:hover {
	transform: translateX(4px);
}

.event-item-content > .category-circle {
	position: absolute;
	top: 40%;
	left: 24px;
}

@keyframes pulse {
	0% {
		box-shadow: 0px 0px 3px 0px rgba(173, 0, 0, 0.3);
	}
	65% {
		box-shadow: 0px 0px 3px 6px rgba(173, 0, 0, 0.3);
	}
	90% {
		box-shadow: 0px 0px 3px 6px transparent;
	}
}

.event-item-content > .active-circle {
	position: absolute;
	top: 40%;
	right: 32px;
	border-radius: 50%;
	animation-name: pulse;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

.event-item-content > p {
	margin: 0;
}

.event-item.past {
	opacity: 0.1;
	border-top: 1px solid #888;
}
