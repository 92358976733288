@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;600;700&display=swap');

.food-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #476AB8;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;
    max-width: 90%;
    background: white;
}

/* Meal Type Header & Time */
.food-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Jost', sans-serif;
    font-weight: 600;
    font-size: 1.6rem;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 12px;
}

.food-card-time {
    font-size: 1.7rem;
    font-weight: 500;
}

/* Adjusting Colors */
.food-card-header.breakfast,
.food-card-time.breakfast,
.food-card-title.breakfast { color: #4B5DE6; }

.food-card-header.lunch,
.food-card-time.lunch,
.food-card-title.lunch { color: #169AE5; }

.food-card-header.dinner,
.food-card-time.dinner,
.food-card-title.dinner { color: #8D52DA; }

/* Restaurant Name */
.food-card-title {
    font-family: 'Jost', sans-serif;
    font-size: 1.8rem;
    font-weight: 400;
    padding-left: 15px;
}

/* Content Container */
.food-card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    padding: 0 15px; 
}

/* Responsive Gap Between Food Description & Image */
.food-card-description {
    font-size: 1.05rem;
    font-family: 'Jost', sans-serif;
    color: #1F256C;
    line-height: 1.5;
    flex: 1;
}

/* GAP ADDED HERE - Only on Large Screens */
.food-card-content {
    gap: 60px; 
}

/* Item Styling */
.food-card-item {
    margin-bottom: 20px; 
}

.item-category {
    font-weight: 500;
}

.item-description {
    font-weight: 400; /* Regular weight */
    color: #1F256C; /* Ensures readability */
}

.food-card-image {
    width: 280px;
    height: 180px;
    border-radius: 8px;
    overflow: hidden;
    background: #e0e0e0;
}

/* Skeleton Loader for Empty Image */
.skeleton-loader {
    width: 100%;
    height: 100%;
    background: #e0e0e0;
    border-radius: 8px;
}

.food-card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Skeleton Loader for Empty Image */
.skeleton-loader {
    width: 100%;
    height: 100%;
    background: #e0e0e0;
    border-radius: 8px;
}

/* Mobile Responsive */
@media (max-width: 768px) {
    .food-card {
        padding: 15px;
    }

    .food-card-content {
        flex-direction: column;
        padding: 10px;
        gap: 0; 
    }

    .food-card-image {
        width: 100%;
        height: 220px;
        margin-top: 15px;
    }

    .food-card-item {
        margin-bottom: 8px;
    }
}