@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600;700&display=swap');

/* Logo styles */
.logo {
  width: 60px;
  height: auto;
  display: block;
}

/* Logo link */
.logo-link {
  left: 20px;
  z-index: 1;
}

/* Main navbar styles */
#navbar-main {
  z-index: 1000;
  user-select: none;
  background: rgba(255, 255, 255, 0.80);
  width: 90%;
  margin: 40px auto;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0;
  box-sizing: border-box;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  height: 70px;
}

/* Navigation link styles */
#basic-navbar-nav .nav-link {
  color: #476AB8;
  padding: 10px 20px;
  margin: 5px;
  font-weight: regular;
  align-items: center;
  border-radius: 2rem;
  transition: background-color 0.3s ease, padding 0.3s ease;
}

#basic-navbar-nav .nav-link:hover {
  background-color: rgb(224, 236, 255);
  text-decoration: none;
  border-radius: 2rem;
}

/* Hamburger menu styles */
.navbar-toggler {
  border: none;
  padding: 0.5rem;
  background: transparent;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.hamburger-icon {
  color: rgba(71, 106, 184, 1);
}

/* Ensure collapse animation works */
.navbar-collapse {
  transition: height 0.3s ease, opacity 0.3s ease;
}

/* Responsive styles */
@media (max-width: 1400px) {
  #navbar-main {
    width: 90%;
  }

  #navbar-main.expanded {
    height: auto;
    border-radius: 25px;
    padding-bottom: 15px;
  }

  #basic-navbar-nav .nav-link {
    text-align: center;
    margin: 5px 0;
  }

  .logo-link {
    position: relative !important;
    left: 0;
  }
}

@media (min-width: 1401px) {
  .navbar-collapse {
    display: flex;
    justify-content: center;
  }

  #basic-navbar-nav .nav-link {
    padding: 10px 20px;
  }

  .logo {
    width: 50px; 
    height: auto;
    padding-bottom: 10px; 
  }
  
}

/* Even smaller logo for mobile screens */
@media (max-width: 768px) {
  .logo {
    width: 50px; 
  }
}