#timeline {
    position: relative;
    overflow: auto;
    width: 100%;
    outline: none;
    height: 80vh;
    border-radius: 12px; 
    background: rgb(255, 255, 255);
    border: 1px solid rgb(216, 228, 255);
    padding: 10px;
}

.timeline-label,
.timeline-label-marker {
    position: absolute;
    font-family: system-ui, -apple-system, sans-serif;
}

.timeline-label {
    top: 6px;
    font-size: 12px;
    color: #555;
    font-weight: normal;
    transition: color 0.2s ease;
    text-align: left;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.timeline-label-marker {
    height: 2%;
    width: 2px;
    background: rgba(0, 0, 0, 0.2);
}

#timeline-slider {
    position: absolute;
    height: 100%;
    width: 3px;
    background: rgba(255, 0, 0, 0.4);
    z-index: 2;
    border-radius: 1px;
}

#timeline-slider .triangle-pointer {
    position: absolute;
    left: -5px;
}

#timeline-slider .triangle-pointer.top {
    top: 0;
}

#timeline-slider .triangle-pointer.bottom {
    bottom: 0;
}

.timeline-track-item.gantt-style {
    position: relative;
    transition: all 0.2s ease;
    cursor: pointer;
    height: 40px;
    border-radius: 4px; 
    margin-bottom: 3px;
}

.timeline-track-item.gantt-style p {
    line-height: 1.2;
    position: absolute;
    margin-left: 7px;
    margin-right: 7px;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    color: white;
    display: flex;
    align-items: center;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    /* Better text truncation */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 20px);
    width: auto;
}

.timeline-track-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #FF7F7F;
    border-radius: 6px;
    z-index: 0;
}

.timeline-track-item:hover {
    opacity: 0.9;
    transform: scale(1.02);
}

.timeline-track-item.past {
    opacity: 0.4;
}

.timeline-track-item.present > .timeline-track-bar {
    box-shadow: 0 0 16px rgba(46, 42, 42, 0.7);
}
