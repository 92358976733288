@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;600;700&display=swap');

/* layout & structure */
#foodmenu-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
}

/* day selector styling */
.day-selector-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.foodmenu-content {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    width: 100%;
    max-width: 1100px;
    margin: 0 auto; 
    padding: 20px;
}

/* meal day section */
.foodmenu-day {
    width: 100%;
    max-width: 900px;
    margin-bottom: 30px;
    font-family: 'Jost', sans-serif;
    text-align: center;
}

/* meal headers */
.foodmenu-day h5 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 700;
    color: white;
    padding: 15px;
    margin: 0 auto;
    border-radius: 12px;
}

/* meal header colors */
.foodmenu-day h5.breakfast {
    background: linear-gradient(135deg, #4b5de6, #3a4fcf);
}

.foodmenu-day h5.lunch {
    background: linear-gradient(135deg, #169ae5, #0d7dbe);
}

.foodmenu-day h5.dinner {
    background: linear-gradient(135deg, #8d52da, #732fb3);
}

/* food card container inside meal day */
.foodmenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
}

/* responsive design */
@media (max-width: 768px) {
    #foodmenu-view {
        padding: 10px;
    }

    .foodmenu-day {
        max-width: 100%;
    }

    .foodmenu {
        padding: 15px;
    }

    .day-selector-container {
        flex-direction: column;
        padding: 10px;
    }
}