/* Customize the entire scrollbar */
::-webkit-scrollbar {
    width: 15px; /* Adjust width of the scrollbar */
    height: 15px; /* If horizontal scrollbar is used */
}

/* Customize the thumb (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
    background-color: #E3E3E3; /* Color of the thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
    border: 2px solid transparent; /* Optional: adds some space around the thumb */
    background-clip: content-box; /* Optional: makes the thumb look nicer */
}

/* Customize the track (the area the thumb slides in) */
::-webkit-scrollbar-track {
    background: transparent; /* Light gray color for the track */
    border-radius: 10px; /* Rounded corners for the track */
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* .fade-in-message {
    animation: fadeIn 0.3s ease-out forwards;
} */


.fade-in-char {
    opacity: 0;
    animation: fadeInChar 0.3s ease forwards;
}

@keyframes fadeInChar {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}