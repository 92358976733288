@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;600&display=swap');

#contact-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 18px 20px 20px;
    margin: 0 auto;
    min-height: fit-content;
}

.contact-title {
    font-family: 'Plus Jakarta Sans', sans-serif;
    background: linear-gradient(90deg, #8D52DA, #4B5DE6, #169AE5);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 4rem;
}

.contact-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    width: 100%;
    padding: 0 20px;
}

.contact-text {
    text-align: left;
    font-family: 'Jost', sans-serif;
    color: #4B75DB;
    max-width: 50%;
}

.contact-section {
    margin-bottom: 2rem;
}

.contact-section h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.contact-section p {
    font-size: 1.125rem;
    font-weight: 400;
}

.contact-image {
    max-width: 50%;
    display: flex;
    justify-content: center;
}

.contact-image img {
    width: 100%;
    max-width: 500px;
    height: auto;
    object-fit: contain;
}

@media (max-width: 768px) {
    .contact-content {
        flex-direction: column;
        text-align: center;
    }

    .contact-text {
        max-width: 100%;
        margin-bottom: 2rem;
    }

    .contact-section {
        text-align: center;
    }

    .contact-image {
        max-width: 80%;
    }
}