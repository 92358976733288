.button-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    width: 100%;
    max-width: 1800px;
    margin: 2rem auto;
    padding: 0 5px;
    justify-items: center; 
    align-items: center;
  }
  
  @media (min-width: 1024px) and (max-width: 1440px) {
    .button-grid {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      gap: 1.5rem;
    }
  }
  
  @media (min-width: 1441px) {
    .button-grid {
      grid-template-columns: repeat(4, 1fr);
      gap: 2rem;
    }
  }
  
  @media (max-width: 1023px) {
    .button-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      max-width: 600px; 
      margin-left: auto;
      margin-right: auto;
    }
  }
