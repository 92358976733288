@import url('https://rsms.me/inter/inter.css');

@font-face {
	font-family: 'Harabara';
	src: url('./assets/fonts/HarabaraMais.otf');
}

@supports (font-variation-settings: normal) {
	html {
		font-family: 'Inter var', sans-serif;
	}
}

body {
	margin: 0;
	font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
		'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: Inter;
}
