@import url('https://fonts.googleapis.com/css2?family=Sulphur+Point:wght@300;400;700&display=swap');

.floor-button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 20px;
}

.floor-button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: none !important;
  width: 45px !important;
  height: 45px !important;
  font-size: 1.2rem !important;
  border-radius: 50% !important;
  margin-bottom: 0.5rem !important;
  font-family: 'Sulphur Point', sans-serif !important;
  color: white !important;
  font-weight: 600 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2) !important;
  transition: all 0.3s ease-in-out !important;
  aspect-ratio: 1 !important; 
}

/* Floor specific colors */
.floor-button:nth-child(1) {
  background-color: #52C298 !important;
}

.floor-button:nth-child(2) {
  background-color: #3BB1D9 !important;
}

.floor-button:nth-child(3) {
  background-color: #81E7D4 !important;
}

.floor-button:nth-child(4) {
  background-color: #9F9FF5 !important;
}

.floor-button:nth-child(5) {
  background-color: #F99F51 !important;
}

.floor-button:nth-child(6) {
  background-color: #EFAFE2 !important;
}

/* Hover states */
.floor-button:hover {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3) !important;
  transform: translateY(-2px) !important;
}

/* Selected states with inverted colors */
.floor-button.selected:nth-child(1) {
  background-color: white !important;
  color: #52C298 !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3) !important;
}

.floor-button.selected:nth-child(2) {
  background-color: white !important;
  color: #3BB1D9 !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3) !important;
}

.floor-button.selected:nth-child(3) {
  background-color: white !important;
  color: #81E7D4 !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3) !important;
}

.floor-button.selected:nth-child(4) {
  background-color: white !important;
  color: #9F9FF5 !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3) !important;
}

.floor-button.selected:nth-child(5) {
  background-color: white !important;
  color: #F99F51 !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3) !important;
}

.floor-button.selected:nth-child(6) {
  background-color: white !important;
  color: #EFAFE2 !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3) !important;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .floor-button-group {
    flex-direction: row !important;
    justify-content: center !important;
    margin-bottom: 20px !important;
  }

  .floor-button {
    font-size: 1rem !important;
    width: 50px !important;
    margin: 5px !important;
  }
}

/* Further adjustments for very small screens */
@media (max-width: 768px) {
  .floor-button {
    font-size: 0.8rem !important;
    width: 40px !important;
  }

  .floor-button-group {
    padding: 0 10px !important;
  }
}